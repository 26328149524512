.separator {
    width: 1px;
    height: 1px;
    border-top: 1px solid darkgray;
}

.custom-scroll {
    max-height: 770px !important;
    overflow-y: auto;
}

.scrollable {
    max-height: 200px !important;
    overflow-y: auto !important;
}

.file-item {
    width: 110px;
    height: 110px;
    border: 1px solid black;
}

.file-item:hover {
    /*font-weight: bold;
    font-size: 0.9rem;*/
    cursor: pointer;
    border: 2px solid dimgray;
    -webkit-box-shadow: -5px 3px 5px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -5px 3px 5px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -5px 3px 5px -2px rgba(0, 0, 0, 0.75);
}

.font-size-1rem {
    font-size: 2rem;
}

.font-size-3rem {
    font-size: 3rem;
}

.custom-input-file {
    display: none;
}

.remove-file {
}

.remove-file:hover {
    font-weight: bold;
    font-size: 1.3rem;
}


