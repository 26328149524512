.Modal {
    transition: all 0.5s ease-out;
    min-width: 500px;
    top: -35px;
    margin-bottom: -40px !important;
}

.ModalOpen {
    animation: openModal 0.5s ease-out forwards;
}

.ModalClosed {
    animation: closeModal 1s ease-out forwards;
}

.fade-slide-enter {

}

.fade-slide-enter-active {
    animation: openModal 0.5s ease-out forwards;
}

.fade-slide-exit-active {
    animation: closeModal 1s ease-out forwards;
}

@keyframes openModal {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes closeModal {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.height-max-content {
    height: max-content;
}

